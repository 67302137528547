<template>
  <div class="blog flex">
    <Header
      @delete="toggleConfirm('delete')"
      :details="{
        title: 'Infodays',
        back: { name: 'blog' },
        actions: {
          delete: true,
        },
      }"
    />
    <div class="col-8 left-side">
      <header class="lang d-flex justify-content-end">
        <Select class="lang-select" v-model="currentLanguage" :options="$store.state.global.availableLanguages" />
      </header>
      <template v-for="(lang, index) in $store.state.global.availableLanguages">
        <template v-if="lang.value === currentLanguage">
          <div class="input-box title" :key="'title-' + index">
            <input type="text" :placeholder="$t('overall.add_title')" v-model="translations[index].title" />
          </div>
          <div class="input-box" :key="'editor-' + index">
            <Editor v-model="translations[index].description" />
          </div>
        </template>
      </template>
      <div class="actions-bar">
        <template v-if="!loading">
          <button class="cancel">{{ $t("overall.cancel") }}</button>
          <button class="cta save fixed-button" @click="save">
            <span class="txt">{{ $t("overall.save") }}</span>
          </button>
        </template>
        <span class="loader" v-else></span>
      </div>
    </div>
    <div style="margin-top: 0" class="col-4 right-side">
      <div class="box-container">
        <div class="box-thumbnail hide">
          <label for="thumbnail" class="add-thumbnail change-image" v-if="!article.image && !tempImage">
            <span>{{ $t("properties.library.add_picture") }}</span>
          </label>
          <label for="thumbnail" v-else>
            <img :src="tempImage ? tempImage : article.image" alt="Thumbnail" />
          </label>
          <input class="hide" ref="image" type="file" @change="previewFiles" id="thumbnail" />
        </div>

        <div class="input-box">
          <div class="d-flex justify-content-between">
            <h2>Dates and Times</h2>
            <button class="colored align-right" @click="datesAndTimes.push({ date: generateTodayDate(), times: [] })">
              + Add Date
            </button>
          </div>
          <div class="date" v-for="(dat, i) in datesAndTimes" :key="i + '-dat'">
            <Datepicker placeholder="Add Date" v-model="dat.date" />
            <button class="remove-date" @click="datesAndTimes.splice(i, 1)"><crossSVG /></button>
            <div class="time" v-for="(t, j) in dat.times" :key="j + '-time'">
              <input type="text" v-model="dat.times[j]" placeholder="Write Time : ex 10h-12h" />
              <button class="remove-time" @click="dat.times.splice(j, 1)"><crossSVG /></button>
            </div>
            <button class="add-time colored align-right" @click="dat.times.push('')">+ Add time</button>
          </div>
        </div>
      </div>
      <div class="box-container">
        <div class="input-box location">
          <div>
            <label>Location</label>
            <input class="location-name" type="text" placeholder="Place Name" v-model="article.address_name" />
          </div>
          <div class="d-flex justify-content-between">
            <AddressAutocomplete placeholder="Type address" v-model="address" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import Editor from "@/components/Editor";
import modalBus from "@/bus/modal-bus";
import notifBus from "@/bus/notification-bus";

export default Vue.extend({
  name: "EditArticle",
  data() {
    return {
      loading: null,
      currentLanguage: this.$cookies.get("usr_lang"),
      translations: [],
      datesAndTimes: [],
      tempImage: null,
      address: null,
      article: {
        publishing_date: null,
        address_name: null,
        image: null,
        is_published: true,
      },
    };
  },
  components: {
    Editor,
  },
  methods: {
    toggleConfirm(which) {
      let functionToCall = which === "delete" ? this.deleteMethod : this.duplicate;
      let message = which === "delete" ? "Want to DELETE this infodays ?" : "Want to DUPLICATE this news ?";
      modalBus.$emit("set-size", {
        width: "400px",
        "margin-top": "50px",
      });
      modalBus.$emit("change", {
        component: "Prompt",
        data: {
          methodToCall: functionToCall,
          message,
        },
      });
      modalBus.$emit("toggle");
    },
    deleteMethod() {
      this.$axios.delete("api/blog/" + this.article.token).then((res) => {
        notifBus.$emit("show", {
          status: "success",
          message: this.$t("projects.notifications.deleted"),
        });
        this.$router.push({ name: "blog" });
      });
    },
    save() {
      this.loading = true;
      const formData = new FormData();
      if (this.article.image && this.article.image.length > 400) {
        this.article.image = null;
      }
      if (this.$refs.image.files[0]) formData.append("image", this.$refs.image.files[0]);

      formData.set("article", JSON.stringify(this.article));
      formData.set("translations", JSON.stringify(this.translations));
      formData.set("address", JSON.stringify(this.address));
      formData.set("dates_and_times", JSON.stringify(this.datesAndTimes));

      this.$axios.post("api/blog", formData, { headers: { "Content-Type": "multipart/form-data" } }).then((res) => {
        this.loading = false;
        const message = this.article.id ? "Infoday updated" : "Infoday created";
        notifBus.$emit("show", {
          status: "success",
          message,
        });
        this.$router.push({ name: "blog" });
      });
    },
    addEmail() {
      this.emails.push({ value: "" });
    },
    removeEmail(toDelete) {
      this.emails = this.emails.filter((el) => el.value !== toDelete);
    },

    addPhone() {
      this.phones.push({ value: "" });
    },
    removePhone(toDelete) {
      this.phones = this.phones.filter((el) => el.value !== toDelete);
    },
    previewFiles(event) {
      let reader = new FileReader();
      let _this = this;
      reader.onload = function() {
        _this.tempImage = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
  },
  watch: {
    "$store.state.global.availableLanguages": {
      immediate: true,
      handler(el) {
        this.translations = this._global.availableLanguages.map((el) => {
          return {
            lang: el.value,
            title: "",
            description: "",
            slug: "",
          };
        });
      },
    },
  },
  created() {
    if (this.$route.params.token !== undefined) {
      this.$axios.get("api/blog/" + this.$route.params.token).then((res) => {
        let article = res.data;
        this.datesAndTimes = res.data.dates_and_times;
        this.address = res.data.address;
        this.translations = this._global.availableLanguages.map((l) => {
          const index = article.translations.findIndex((t) => t.lang === l.value);
          if (index !== -1) {
            const temp = { ...article.translations[index] };
            article.translations.splice(index, 1);
            return temp;
          }

          return {
            lang: l.value,
            title: "",
            description: "",
            slug: "",
          };
        });

        this.article = res.data.article;
      });
    }
  },
});
</script>
